import getDecodedOpco from "./getDecodeOpco";

const getOpco = () => {
  const opco = window?.location?.pathname.split("/")[1];

  if (opco !== "") {
    return opco;
  } else {
    return getDecodedOpco();
  }
};

export default getOpco;
