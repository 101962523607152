import { FunctionComponent } from "react";

import { useSelector } from "react-redux";

import { t } from "i18next";

import { HomePageBannerContainer, PromotionsSubTitle, PromotionsTitle } from "./styles";

import { availableOffers } from "state/offers-slice";

const HomePageBanner: FunctionComponent<{
  currentPageText?: string;
  background?: string;
  showSubtitle: boolean;
  currentTab?: string;
}> = ({ currentPageText, background, showSubtitle, currentTab }) => {
  const offers = useSelector(availableOffers);

  return (
    <HomePageBannerContainer background={background}>
      <PromotionsTitle>{currentPageText}</PromotionsTitle>
      {showSubtitle &&
        (currentTab === "available" ? (
          <PromotionsSubTitle>{`${t("We have")} ${offers.length === 1 ? "o" : offers.length} ${t(
            "Offers available for you",
          )}`}</PromotionsSubTitle>
        ) : (
          <PromotionsSubTitle>{`${t("Information help to download apps")}`}</PromotionsSubTitle>
        ))}
    </HomePageBannerContainer>
  );
};

export default HomePageBanner;
