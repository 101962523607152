import styled from "styled-components";

import { TooltipBase, ToolTipText } from "components/CustomTooltip/styles";

export const OfferBanner = styled.img`
  max-width: 100%;
  display: flex;
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferBanner};
`;

export const OfferDetailsSection = styled.section`
  padding: ${({ theme }) => theme.customTheme.pxToRem(30)} ${({ theme }) => theme.customTheme.pxToRem(20)};
  background-color: ${({ theme }) => theme.customTheme.palette.white.standard};
  &:nth-child(2n) {
    background-color: ${({ theme }) => theme.customTheme.palette.grey.background};
  }
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferDetailsSection};
`;

export const OfferDetailsSectionMainSection = styled.div`
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferDetailsSectionMainSection};
`;

export const OfferDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.customTheme.pxToRem(20)};
  margin-bottom: ${({ theme }) => theme.customTheme.pxToRem(20)};
  & > img {
    width: 142px;
    margin-top: 0px;
    height: 38px;
  }
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferDetailsHeader};
`;

export const OfferDetailsMainText = styled.p`
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
  }
  p {
    font-family: ThemeFontLight;
    strong {
      font-family: ThemeFontMedium;
      color: ${({ theme }) => theme.customTheme.palette.black.dark};
    }
  }
  padding-top: ${({ theme }) => theme.customTheme.pxToRem(10)};

  * {
    ${({ theme }) => theme.customTheme.typography.primaryText};
    margin-top: ${({ theme }) => theme.customTheme.pxToRem(10)};
  }
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferDetailsMainText};
`;

export const OfferDetailsAditionalText = styled.div`
  column-count: 2;
  column-gap: 20px;
  overflow-wrap: break-word;

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    column-count: 1;
  }

  p {
    font-family: ThemeFontLight;
    strong {
      font-weight: normal;
      font-family: ThemeFontMedium;
    }
    a {
      color: ${({ theme }) => theme.customTheme.palette.blue.dark};
    }
  }

  h6 {
    ${({ theme }) => theme.customTheme.typography.h6};
  }
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferDetailsAditionalText};
`;

export const OfferDetailsHeading = styled.h3`
  ${({ theme }) => theme.customTheme.typography.h3};
  margin-bottom: 0px;
  margin-top: 8px;
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferDetailsHeading};
`;

export const OfferDetailsSubHeading = styled.h4`
  ${({ theme }) => theme.customTheme.typography.h4};
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferDetailsSubHeading};
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.customTheme.pxToRem(20)};
  background-color: ${({ theme }) => theme.customTheme.palette.grey.standard};
  width: 50%;

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    width: 100%;
    align-items: center;
  }
`;

export const BottomSectionTitle = styled.h2`
  ${({ theme }) => theme.customTheme.typography.bottomSectionTitle};
`;

export const BottomSectionSubTitle = styled.span`
  margin-bottom: ${({ theme }) => theme.customTheme.pxToRem(16)};
  ${({ theme }) => theme.customTheme.typography.bottomSectionSubTitle};
`;

export const BottomSectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.customTheme.palette.grey.background};
  color: ${({ theme }) => theme.customTheme.palette.black.standard};
  padding: ${({ theme }) => theme.customTheme.pxToRem(20)} ${({ theme }) => theme.customTheme.pxToRem(20)};
  gap: ${({ theme }) => theme.customTheme.pxToRem(10)};

  ${({ theme }) => theme.customTheme.breakpoints.up("md")} {
    flex-direction: row;
  }
`;

export const HelpLink = styled.a`
  ${({ theme }) => theme.customTheme.typography.helpLinks};
  background-color: ${({ theme }) => theme.customTheme.palette.grey.background};
  margin-bottom: ${({ theme }) => theme.customTheme.pxToRem(10)};
  padding: ${({ theme }) => theme.customTheme.pxToRem(14)} ${({ theme }) => theme.customTheme.pxToRem(10)};
  width: 100%;
`;

export const HelpLinkLabel = styled.span`
  margin-left: ${({ theme }) => theme.customTheme.pxToRem(16)};
`;

export const ActivationWarning = styled(TooltipBase)`
  height: ${({ theme }) => theme.customTheme.pxToRem(140)};
  color: ${({ theme }) => theme.customTheme.palette.grey.text};

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    width: 100%;
    height: ${({ theme }) => theme.customTheme.pxToRem(199)};
  }

  ${({ theme }) => theme.customTheme.breakpoints.down("sm")} {
    width: 100%;
    height: ${({ theme }) => theme.customTheme.pxToRem(280)};
  }

  ${({ theme }) => theme.customTheme.breakpoints.up("lg")} {
    height: ${({ theme }) => theme.customTheme.pxToRem(133)};
    width: 50%;
  }
`;

export const ActivationWarningText = styled(ToolTipText)`
  background-color: ${({ theme }) => theme.customTheme.palette.white.standard};
`;

export const DownloadButtonsContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  & > button {
    margin-top: 20px;
  }
`;

export const DownloadAnchor = styled.a`
  margin-right: 10px;
  max-width: 48%;
`;

export const DownloadImage = styled.img`
  max-height: 2.8125em;
  max-width: 100%;
`;
