import { FunctionComponent, ReactElement, useEffect, useRef } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
  useNavigate,
  useNavigationType,
  useSearchParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

import OfferComparison from "routes/OfferComparison";
import { setModal } from "state/config-slice";
import { AppDispatch } from "state/reducers-combiner";
import Home from "routes/Home";
import OfferDetails from "routes/OfferDetails";
import ErrorPage from "routes/ErrorPage";
import NotFound from "routes/NotFound";
import { fetchUserFromAPI, userState } from "state/user-slice";
import { fetchOffersFromAPI, offersState, selectOfferByName, selectOfferByPartner } from "state/offers-slice";
import { sendULFEvent } from "state/config-slice";
import { ULFEvents } from "types/ULF";
import Offer from "types/Offer";
import CookiePreferences from "routes/CookiePreferences";
import { useRefreshUser } from "utils/UseRefreshUser";
import getOpco from "utils/getOpco";
import { useSourceParams } from "utils/useSourceParams";

const AppRoutes: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();

  const offers = useSelector(offersState);
  const userInfo = useSelector(userState);
  const user = useSelector(userState);
  const [cookies] = useCookies();
  const { name } = useParams<{ name?: string }>();
  const selectedOffer: Offer = useSelector(selectOfferByName(name));
  const location = useLocation();
  const navigationType = useNavigationType();
  const prevLocationValue = useRef({ ...location });

  const [searchParams, setSearchParams] = useSearchParams();
  const possiblePartnerToDeeplink = offers.offersList.length == 1 ? offers.offersList[0].partner : null;
  const selectedOfferToDeeplink: Offer = useSelector(selectOfferByPartner(possiblePartnerToDeeplink));
  const navigate = useNavigate();
  const [GetUserInfoFromCookies] = useRefreshUser();
  const [, setCookie] = useCookies();

  useSourceParams(searchParams);

  useEffect(() => {
    // TODO:: TO BE REMOVED WHEN COOKIES POLICY IS DONE
    if (cookies["PPE_COOKIE_CONSENT_GRANTED"] !== "true") {
      dispatch(setModal({ showModal: true, cookieModal: true }));
    }
  }, [dispatch, cookies]);

  useEffect(() => {
    const preAuthCode = searchParams.get("code");
    searchParams.delete("state");
    if (preAuthCode) {
      dispatch(fetchUserFromAPI(preAuthCode))
        .unwrap()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((originalPromiseResult: any) => {
          if (originalPromiseResult.userInfo) {
            const userInfo = originalPromiseResult.userInfo;
            setCookie("access_token", userInfo.tokenResponse.access_token, { secure: true, sameSite: "strict" });
            setCookie("phone_number", userInfo.userInfoResponse.phone_number, { secure: true, sameSite: "strict" });
            setCookie("opco", getOpco(), { secure: true, sameSite: "strict" });
          }
        });

      searchParams.delete("code");
      setSearchParams(searchParams);
    } else if (selectedOfferToDeeplink) {
      const offerPages = selectedOfferToDeeplink.json;
      navigate(`offer/${offerPages.partner}-${offerPages.type}`);
    } else {
      GetUserInfoFromCookies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOfferToDeeplink]);

  useEffect(() => {
    if (user.userInfo.access_token) {
      dispatch(fetchOffersFromAPI());
    }
  }, [dispatch, user.userInfo.access_token]);

  // const authenticationProxy = (elementIfLoggedIn: ReactElement, emptyCart: boolean, fallback = <NoAuthPage />) => {
  //   return user.loggedIn && emptyCart ? elementIfLoggedIn : fallback;
  // };

  const loadedContentProxy = (elementIfLoggedIn: ReactElement) => {
    return offers.offersList.length > 0 && elementIfLoggedIn;
  };

  useEffect(() => {
    if (cookies.PPE_TRACKING === "true" && prevLocationValue.current.pathname !== location.pathname) {
      if (navigationType === "POP") {
        dispatch(
          sendULFEvent({
            "event-name": ULFEvents.GO_BACK,
            page: location.pathname,
          }),
        );
      }

      dispatch(
        sendULFEvent({
          "event-name": ULFEvents.PAGE_VIEW,
          page: location.pathname,
          "offer-name": selectedOffer?.name,
          partner: selectedOffer?.partner,
        }),
      );

      prevLocationValue.current = location;
    }
  }, [location, dispatch, cookies.PPE_TRACKING, navigationType, prevLocationValue, selectedOffer]);

  return (
    <Routes>
      <Route path={""} element={userInfo.loggedIn && <Home />} />
      <Route path={"offer/:name"} element={loadedContentProxy(<OfferDetails />)} />
      <Route path={"comparison/:partner"} element={loadedContentProxy(<OfferComparison />)} />
      <Route path={"cookie-preferences"} element={<CookiePreferences />} />

      <Route path={"error"} element={<ErrorPage />} />
      <Route path={"notfound"} element={<NotFound />} />

      <Route path="*" element={<Navigate to="/notfound" />} />
    </Routes>
  );
};

export default AppRoutes;
