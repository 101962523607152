/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import UserSlice, { UserSliceFromRoot } from "types/UserSlice";

import { fetchOffersFromAPI } from "state/offers-slice";

import { API_URL } from "config";
import { fetchConfigFromAPI, setLoader } from "state/config-slice";
import getOpco from "utils/getOpco";

const initialState = {
  loaded: false,
  loading: false,
  loggedIn: false,
  userInfo: {},
  choosenChargingId: null,
} as UserSlice;

export const fetchUserFromAPI = createAsyncThunk("user/fetchUser", async (code: string, { getState }: any) => {
  const source = getState().config.source;

  const response = await fetch(`${API_URL}/api/v3/auth/token/${getOpco()}/${code}?state=&source=${source}`).then(
    async (response) => {
      const userInfo = await response.json();

      if (response.status === 200) {
        return { loggedIn: true, userInfo };
      }

      return { loggedIn: false, userInfo };
    },
  );

  return response;
});

export const logoutUserFromAPI = createAsyncThunk("user/logoutUser", async (_arg, { getState, dispatch }: any) => {
  const userInfo: { access_token: string } = getState().user.userInfo;

  const response = await fetch(`${API_URL}/logout`, {
    credentials: "include",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userInfo.access_token}`,
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ opco: getOpco(), msisdn: getState().user.choosenChargingId?.chargingId }),
  }).then(async (response) => {
    if (response.status === 200) {
      dispatch(fetchConfigFromAPI());
      dispatch(fetchOffersFromAPI());
      dispatch(setLoader({ showLoader: true, message: "Processing your request" }));

      return { loggedIn: false };
    }

    dispatch(setLoader({ showLoader: true, message: "Processing your request" }));

    return { loggedIn: true };
  });

  return response;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    selectCharginID: (state, action) => {
      return {
        ...state,
        choosenChargingId: action.payload,
      };
    },
    setUserInfo: (state, action) => {
      return {
        ...state,
        userInfo: { ...action.payload },
      };
    },
    setLoggedIn: (state, action) => {
      return {
        ...state,
        loggedIn: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserFromAPI.fulfilled, (state, action) => {
        state.userInfo = action.payload.userInfo.tokenResponse;
        state.choosenChargingId = action.payload.userInfo.userInfoResponse
          ? action.payload.userInfo.userInfoResponse.phone_number
          : null;

        state.loaded = true;
        state.loading = false;
        state.loggedIn = action.payload.loggedIn;
      })
      .addCase(fetchUserFromAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutUserFromAPI.pending, (state) => {
        state.loaded = false;
        state.loading = true;
        state.loggedIn = true;
      })
      .addCase(logoutUserFromAPI.fulfilled, () => {
        return { ...initialState };
      });
  },
});

export const { selectCharginID, setUserInfo, setLoggedIn } = userSlice.actions;

export const userState = (state: UserSliceFromRoot): UserSlice => state.user;

export const fetchedUser =
  (): any =>
  (state: UserSliceFromRoot): any => {
    return state?.user?.userInfo || null;
  };

export default userSlice.reducer;
