import { FunctionComponent, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { IconClose } from "@vfuk/uk-legacy-icons";

import { useCookies } from "react-cookie";

import {
  StyledBoxArea,
  StyledPageTitle,
  LogoutModalContainer,
  BackToHomepageButton,
  LogoutButton,
  HeaderLogOut,
} from "./styles";

import { setLoader, setModal } from "state/config-slice";

const LogoutModal: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, , removeCookie] = useCookies();

  const logOut = useCallback(() => {
    dispatch(setLoader({ showLoader: true, message: "" }));
    removeCookie("access_token");
    removeCookie("phone_number");
    removeCookie("PPE_COOKIE_CONSENT_GRANTED");
    removeCookie("PPE_TRACKING");
    removeCookie("opco");

    window.location.href = window.location.origin;
  }, [dispatch, removeCookie]);

  return (
    <LogoutModalContainer>
      <HeaderLogOut>
        <span
          onClick={() => {
            dispatch(setModal({ showModal: false }));
          }}
          onKeyDown={() => {
            dispatch(setModal({ showModal: false }));
          }}
          data-testid="close-logout-modal"
          role="button"
          tabIndex={0}
        >
          <IconClose inverse={false} />
        </span>
      </HeaderLogOut>
      <img src="static-ppe2/icons/my-vodafone-or-profile-hi.webp" alt="logout icon"></img>
      <StyledPageTitle>
        <Trans i18nKey="Do you want to logout?" />
      </StyledPageTitle>
      <StyledBoxArea>
        <LogoutButton id="logout-button" onClick={logOut} title={t("Logout")} />
        <BackToHomepageButton
          data-testid="not-found-back"
          onClick={() => {
            dispatch(setModal({ showModal: false }));
          }}
        >
          {t("No thanks")}
        </BackToHomepageButton>
      </StyledBoxArea>
    </LogoutModalContainer>
  );
};

export default LogoutModal;
