import { IconTick } from "@vfuk/uk-legacy-icons";

import { Trans } from "react-i18next";

import { IconClose } from "@vfuk/source-system-icons";

import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

import { useDispatch } from "react-redux";

import { t } from "i18next";

import { useMemo } from "react";

import {
  Background,
  Progress,
  LoaderContainer,
  PurchaseModalContainer,
  PurchaseOverlay,
} from "../PurchaseLoader/styles";

import { BackToOffersButton, CancelConfimationMessage, CancelLoaderBody, CancelLoaderTitle } from "./style";

import { HeaderLogOut, StyledBoxArea } from "containers/LogoutModal/styles";

import { AppDispatch } from "state/reducers-combiner";
import { fetchOffersFromAPI } from "state/offers-slice";

interface PurchaseLoadeProps {
  percent: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPercent: ActionCreatorWithPayload<any, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  offer: any;
}

export default function CancelLoader({ percent, setPercent, offer }: PurchaseLoadeProps) {
  const dispatch: AppDispatch = useDispatch();

  const cancelPageSucess = offer.json?.pages["cancellation"].success;

  const cancelationConcluded = percent === 100;

  const defineChildrenTemplate = useMemo(() => {
    if (cancelationConcluded) {
      return (
        <>
          <CancelLoaderTitle>
            <Trans i18nKey="Unsubscribe Completed" />
          </CancelLoaderTitle>
          <IconTick state="error" inverse={false} size={5} />
          <>
            <CancelConfimationMessage>{cancelPageSucess.title}</CancelConfimationMessage>
            <CancelConfimationMessage dangerouslySetInnerHTML={{ __html: cancelPageSucess.primaryText }} />
          </>
          <StyledBoxArea>
            <BackToOffersButton
              id="go-back-to-offers-btn"
              onClick={() => {
                dispatch(fetchOffersFromAPI());
                dispatch(setPercent(0));
              }}
              title={t("Go back to offers")}
            />
          </StyledBoxArea>
        </>
      );
    } else {
      return (
        <>
          <CancelLoaderTitle>
            <Trans i18nKey="Cancelling your offer" />
          </CancelLoaderTitle>
          <IconTick state="error" inverse={false} size={5} />
          <CancelConfimationMessage>
            <Trans i18nKey="Confirmare anulare..." />
          </CancelConfimationMessage>
        </>
      );
    }
  }, [cancelationConcluded, cancelPageSucess, dispatch, setPercent]);

  return (
    <PurchaseOverlay>
      <PurchaseModalContainer>
        {percent !== 100 && (
          <LoaderContainer>
            <Background />
            <Progress percent={percent} />
          </LoaderContainer>
        )}
        <CancelLoaderBody concluded={cancelationConcluded}>
          <HeaderLogOut>
            <span
              onClick={() => {
                dispatch(fetchOffersFromAPI());
                dispatch(setPercent(0));
              }}
              onKeyDown={() => {
                dispatch(fetchOffersFromAPI());
                dispatch(setPercent(0));
              }}
              role="button"
              tabIndex={0}
              data-testid="close-cancel-btn"
            >
              <IconClose inverse={false} size={5} />
            </span>
          </HeaderLogOut>
          {defineChildrenTemplate}
        </CancelLoaderBody>
      </PurchaseModalContainer>
    </PurchaseOverlay>
  );
}
