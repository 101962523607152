import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

import { fetchOffersFromAPI } from "state/offers-slice";
import { AppDispatch } from "state/reducers-combiner";
import { setUserInfo, setLoggedIn, selectCharginID } from "state/user-slice";

export const useRefreshUser = () => {
  const dispatch: AppDispatch = useDispatch();

  const [cookies] = useCookies();

  const GetUserInfoFromCookies = useCallback(async () => {
    const token = cookies["access_token"];
    const phone_number = cookies["phone_number"];

    if (token) {
      await dispatch(
        setUserInfo({
          access_token: token,
        }),
      );

      await dispatch(setLoggedIn(true));
      await dispatch(selectCharginID(phone_number));
    } else {
      dispatch(fetchOffersFromAPI());
    }
  }, [cookies, dispatch]);

  return [GetUserInfoFromCookies] as const;
};
