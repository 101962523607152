import { FC, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { IconBundles } from "@vfuk/source-hifi-light-icons";

import { CardGrid, Tabs, Tab, EmptyListText } from "./styles";

import { availableOffers, purchasedOffers } from "state/offers-slice";
import OfferCard from "containers/OfferCard";
import { PageContainer } from "containers/PageContainer";
import HomePageBanner from "containers/HomePageBanner";
import IconWrapper from "containers/IconWrapper";

const Home: FC = () => {
  const offers = useSelector(availableOffers);
  const purchased = useSelector(purchasedOffers);
  const [shownTab, setShowTab] = useState("available");

  const { t } = useTranslation();
  const tabs = [
    {
      name: "available",
      list: offers,
      emptyText: t("No offers to subscribe"),
      text: t("Our offers"),
      banner: t("Entertainment for you"),
    },
    {
      name: "purchased",
      list: purchased,
      emptyText: t("No active subscriptions"),
      text: t("Manage Subscriptions"),
      banner: t("Your subscriptions"),
    },
  ];

  const chosenInfoToDisplay = tabs.filter((tab) => tab.name === shownTab)[0];

  return (
    <>
      <HomePageBanner
        currentPageText={chosenInfoToDisplay.banner}
        showSubtitle
        currentTab={shownTab}
        background="images/offer-header-background.png"
      />
      <Tabs>
        {tabs.map((tab) => (
          <Tab
            key={`tab${tab.name}`}
            data-testid={`tab-button-${tab.name}`}
            active={tab.name === shownTab}
            onClick={() => setShowTab(tab.name)}
          >
            {tab.text}
          </Tab>
        ))}
      </Tabs>
      <PageContainer>
        {chosenInfoToDisplay.list && chosenInfoToDisplay.list.length > 0 ? (
          <CardGrid data-testid="home-offers-list" numberOfOffers={chosenInfoToDisplay.list.length}>
            {chosenInfoToDisplay.list.map((el, index) => (
              <OfferCard
                key={el.json.name}
                offer={el}
                numberOfOffers={chosenInfoToDisplay.list.length}
                offerPosition={index}
              />
            ))}
          </CardGrid>
        ) : (
          <>
            <IconWrapper>
              <IconBundles size={5} />
            </IconWrapper>
            <EmptyListText data-testid="noOffersToDisplayHeading">{chosenInfoToDisplay.emptyText}</EmptyListText>
          </>
        )}
      </PageContainer>
    </>
  );
};

export default Home;
