import styled from "styled-components";

import { PrimaryButton } from "components/Button";

interface ISectionProps {
  title?: string;
  type?: string;
  size?: string;
}

export const OverlayContainer = styled.div`
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  position: fixed;
  background-color: ${({ theme }) => theme.customTheme.palette.semiTransparentBackground.standard};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledContainer = styled.section<ISectionProps>`
  z-index: 99999;
  position: fixed;
  width: ${({ type }) => (type === "logout" ? "100%" : "430px")};
  height: ${({ type }) => (type === "logout" ? "100%" : "682px")};
  min-height: 100%;
  max-height: ${({ size }) => (size === "small" ? "348px" : "708px")};
  background-color: ${({ theme }) => theme.customTheme.palette.white.standard};
  display: flex;
  flex-direction: column;
  align-items: ${({ type }) => (type === "logout" ? "center" : "flex-start")};
  padding: 30px 30px;
  gap: 16px;
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.15);

  ${({ theme }) => theme.customTheme.breakpoints.up("lg")} {
    width: ${({ type }) => {
      if (type === "logout") {
        return "100%";
      } else {
        type === "error" || type === "cancelation" || type === "cookies" ? "448px" : "682px";
      }
    }};
  }
`;

export const StyledCookieContainer = styled.section<ISectionProps>`
  z-index: 99999;
  position: fixed;
  width: 327px;

  min-height: 348px;
  max-height: ${({ size }) => (size === "small" ? "348px" : "708px")};
  background-color: ${({ theme }) => theme.customTheme.palette.white.standard};
  display: flex;
  flex-direction: column;
  align-items: ${({ type }) => (type === "logout" ? "center" : "flex-start")};
  padding: 48px 32px;
  gap: 16px;
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.15);

  ${({ theme }) => theme.customTheme.breakpoints.up("lg")} {
    width: 760px;
    padding: 56px 64px;
  }
`;

export const StyledBigIcon = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.customTheme.breakpoints.up("lg")} {
    padding-top: 16px;
  }
`;
interface ITextAlignProps {
  textAlign?: string | null;
}

export const StyledTitle = styled.p<ITextAlignProps>`
  width: 100%;
  text-align: ${({ textAlign }) => textAlign || "center"};
  margin: 0px auto;
  font-family: ThemeFontBold;
  font-size: ${({ theme }) => theme.customTheme.pxToRem(24)};
  line-height: 32px;
  font-weight: 700;
  color: ${({ theme }) => theme.customTheme.palette.black.black1};
`;

export const StyledDescription = styled.p<ITextAlignProps>`
  width: 100%;
  text-align: ${({ textAlign }) => textAlign || "center"};
  font-family: ThemeFontRegular;
  font-size: ${({ theme }) => theme.customTheme.pxToRem(16)};
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.customTheme.palette.grey.grey5E};
  margin: 0px auto;
`;

export const ChildrenWrapper = styled.div<ITextAlignProps>`
  width: 100%;
  font-family: ThemeFontRegular;
  font-size: ${({ theme }) => theme.customTheme.pxToRem(16)};
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.customTheme.palette.grey.grey5E};
  text-align: ${({ textAlign }) => textAlign || "center"};
  overflow-y: auto;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  :after {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 40%;
    pointer-events: none;
    border-radius: 0px 0px 12px 12px;
  }
`;

export const ButtonContainer = styled.div`
  padding-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const DoneButton = styled(PrimaryButton)`
  width: 105px;
  background-color: ${({ theme }) => theme.customTheme.palette.black.black25};
`;

export const StyledHeader = styled.section`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  ${({ theme }) => theme.customTheme.breakpoints.up("md")} {
    justify-content: center;
  }
`;

export const StyledCookieHeader = styled.section`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const StyledCookieTitle = styled.div`
  padding-top: 4px;
  font-family: ThemeFontLight;
  font-size: 40px;
  margin-left: 16px;
  line-height: 40px;

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    padding-top: 0px;
    margin-left: 6px;
    margin-top: 16px;
  }
`;

export const StyledCookieDescription = styled.span`
  line-height: 24px;
  margin-bottom: 16px;
  font-family: ThemeFontLight;
`;

export const StyledButtonsArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ theme }) => theme.customTheme.breakpoints.up("md")} {
    flex-direction: row;
    justify-content: center;
  }
`;

export const StyledCookieButtonsArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  ${({ theme }) => theme.customTheme.breakpoints.up("lg")} {
    flex-direction: row;
  }
`;

export const CookiesBtn = styled(PrimaryButton)`
  padding: ${({ theme }) => theme.customTheme.pxToRem(10)} ${({ theme }) => theme.customTheme.pxToRem(24)};
  font-size: ${({ theme }) => theme.customTheme.pxToRem(16)};
  width: 100%;
  background-color: ${({ theme }) => theme.customTheme.palette.black.black25};
`;

export const StyledIcon = styled.i`
  display: flex;
`;

export const StyledPageFeedbackTitle = styled.h3`
  font-family: ThemeFontLight;
  font-size: 3.5em;
  text-align: center;
  opacity: 1;
  color: ${({ theme }) => theme.customTheme.palette.black.standard};
  font-style: normal;
  letter-spacing: 0px;
  line-height: 30px;
  margin: 0px;

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    font-size: 1.75em;
  }
`;

export const FeedBackModalContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 32px;

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    gap: 16px;
  }
`;
