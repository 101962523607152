interface ResponseType {
  redirect_url: string;
  reservation_id: number;
  subscription_id: number;
  offers: [];
}

const redirectPage = (response: ResponseType) => {
  if (response.redirect_url) {
    window.location.href = response.redirect_url;
    return;
  }
};

export default redirectPage;
