import { FunctionComponent, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import Toggle from "@vfuk/core-toggle";
import { useCookies } from "react-cookie";

import {
  CollapsibleContainer,
  CollapsibleContainerBody,
  CollapsibleContainerHeader,
} from "@vfuk/core-collapsible-container";

import {
  StyledBoxArea,
  StyledContainer,
  CookieHeader,
  CookiesBtn,
  ButtonWrapper,
  CookieTitle,
  CookieAlwaysOn,
  CookieDescription,
  StyledContentArea,
  BannerSection,
} from "./styles";

import HomePageBanner from "containers/HomePageBanner";

const CookiePreferences: FunctionComponent = () => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies();

  const [trackingValue, setTrackingValue] = useState(cookies.PPE_TRACKING === "true" ? true : false);

  const acceptAll = useCallback(() => {
    setCookie("PPE_TRACKING", true, { secure: true, sameSite: "strict" });
    setTrackingValue(true);
    setCookie("PPE_COOKIE_CONSENT_GRANTED", true, { secure: true, sameSite: "strict" });
  }, [setCookie]);

  const acceptWithValueForTracking = useCallback(() => {
    setCookie("PPE_TRACKING", trackingValue);
    setCookie("PPE_COOKIE_CONSENT_GRANTED", true, { secure: true, sameSite: "strict" });
  }, [setCookie, trackingValue]);

  return (
    <>
      <BannerSection id="homepage-banner">
        <HomePageBanner currentPageText={t("Privacy")} showSubtitle={false} />
      </BannerSection>
      <StyledContainer>
        <StyledBoxArea>
          <StyledContentArea>
            <CookieHeader>
              <CookieTitle>{t("Strictly Necessary Cookies")}</CookieTitle>
              <CookieAlwaysOn>{t("Always on")}</CookieAlwaysOn>
            </CookieHeader>
            <CookieDescription>
              {t(
                "These are general purpose identifiers essential for the application to function and therefore always enabled. They are used to maintain a logged-in-status so you can purchase offers or manage your subscriptions and are normally combinations of randomly generated letters and numbers.",
              )}
            </CookieDescription>
          </StyledContentArea>
          <CollapsibleContainer appearance="primary">
            <CollapsibleContainerHeader>{t("What is this?")}</CollapsibleContainerHeader>
            <CollapsibleContainerBody>
              <dl>
                <dt>JSESSIONID, openidAccessToken, openidRefreshToken, uid</dt>
                <dd>
                  {t(
                    "These cookies are used to keep you logged in as you browse the application, and to confirm your identity so you can make purchases and manage your subscriptions.",
                  )}
                </dd>
                <dt>PPE_COOKIE_CONSENT_GRANTED</dt>
                <dd>
                  {t(
                    "The PPE_COOKIE_CONSENT_GRANTED cookie stores your consent for the use of the above cookies so you don't see the prompt to review your privacy preferences.",
                  )}
                </dd>
                <dt>PPE_TRACKING_ALLOWED</dt>
                <dd>
                  {t(
                    "The PPE_TRACKING_ALLOWED cookie stores your consent for the event tracking inside the application (for example, what offers you viewed or accepted).",
                  )}
                </dd>
              </dl>
            </CollapsibleContainerBody>
          </CollapsibleContainer>
        </StyledBoxArea>
        <StyledBoxArea>
          <StyledContentArea>
            <CookieHeader>
              <CookieTitle>{t("Performance")}</CookieTitle>
              <Toggle
                id="default-toggle-01"
                showIcons={false}
                name="the-default-toggle"
                value=""
                isActive={trackingValue}
                onChange={() => {
                  setTrackingValue(!trackingValue);
                }}
              />
            </CookieHeader>
            <CookieDescription>
              {t(
                "These cookies/similar technologies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information collected is aggregated and therefore anonymous. If you do not allow these, we will not know when you have visited our site, and will not be able to monitor and improve its performance.",
              )}
            </CookieDescription>
          </StyledContentArea>

          <CollapsibleContainer appearance="primary">
            <CollapsibleContainerHeader>{t("What is this?")}</CollapsibleContainerHeader>
            <CollapsibleContainerBody>
              <dl>
                <dt>_ga, _gid, _ga_${"<container-id>"}</dt>
                <dd>
                  {t(
                    "These Google Analytics cookies are used to measure use of the website. We use this knowledge to improve our sites. These cookies are stored for a maximum of 2 years.",
                  )}
                </dd>
              </dl>
            </CollapsibleContainerBody>
          </CollapsibleContainer>
        </StyledBoxArea>
        <ButtonWrapper>
          <CookiesBtn id="accept-cookies" onClick={acceptAll} title={t("Accept all")} />
          <CookiesBtn id="reject-cookies" onClick={acceptWithValueForTracking} title={t("Save preferences")} />
        </ButtonWrapper>
      </StyledContainer>
    </>
  );
};

export default CookiePreferences;
