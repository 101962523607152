const getDecodedOpco = () => {
  const search = window?.location?.search;
  const origin = window?.location?.origin;
  let opco = "";

  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const path = params.state ? atob(params.state) : "";

  if (path.length > 0) {
    window.history.replaceState("", "", `${origin}${path}?code=${params.code}`);
    opco = path.split("/")[1];
  }

  return opco;
};

export default getDecodedOpco;
